import React from "react"
import Footer from "../components/Footer"
import SEO from "../components/seo"
import Header from "../components/Header"

export default function Partner() {
  return (
    <div>
      <SEO title="Be a partner" />
      <Header currentPage="" />
      <div className="container m-top">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h1>Be a partner</h1>
            {/* <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugit
              sapiente maxime dolore nemo consequatur odio ratione ex
            </p> */}
            <form className="mt-4">
              <div class="form-group">
                <label htmlFor="exampleInputEmail1">Name</label>
                <input type="text" class="form-control" />
                <br />
                <label htmlFor="exampleInputEmail1">Email</label>
                <input type="email" class="form-control" />
                <br />

                <label htmlFor="exampleInputEmail1">Message</label>
                <textarea class="form-control" rows="10" />
                <br />
                <button type="submit" class="btn btn-primary">
                  Submit Request
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </div>
  )
}
